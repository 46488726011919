import imageUrlBuilder from '@sanity/image-url'

import sanityConfig from '../../../studio/sanity.json'

const builder = imageUrlBuilder(sanityConfig.api)

export function imageUrlFor (source) {
  // TODO update when using video format instead of .gif
  if (source.asset._ref.match(/gif$/)) {
    return builder
      .image(source)
      .quality(80)
  }
  return builder
    .image(source)
    .format('webp')
    .auto('format')
    .quality(80)
}
